import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import { MEDIA_QUERY_MD, MEDIA_QUERY_XL } from "../../../utilities/constants/mediaQueries";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import HomeButton from "../HomeButton";
import HomeHeading from "../HomeHeading";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  padding: 2rem 1rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    padding: 6rem 1rem;
  }
`;

const HeadingContainer = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  padding: 0 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    max-width: 600px;
    text-align: center;
  }
`;

const CreatorList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  padding: 2rem 2rem 0;

  @media (min-width: ${MEDIA_QUERY_XL}) {
    justify-content: space-between;
    gap: 1rem;
    padding: 4rem 4rem 0;
  }
`;

const CreatorContainer = styled(Link)<{ version: number }>`
  position: relative;
  display: flex;
  background: var(--color-gray-7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 2.5rem 1.75rem 1rem 1.75rem;
  margin-bottom: 3rem;
  min-width: 18rem;
  width: calc(25% - 2rem);
  border: 2px solid var(--color-gray-1);
  ${({ version }) => version === 1 && "border-radius: 0rem 0.5rem 0.5rem 0.5rem;"}
  ${({ version }) => version === 2 && "border-radius: 0.5rem 0.5rem 0rem 0.5rem;"}
  transform: translate(-6px, -6px);
  box-shadow: 4px 4px 0px var(--color-gray-1);
  height: fit-content;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  z-index: 2;

  &:hover {
    box-shadow: 6px 6px 0px var(--color-primary);
    transform: translate(-8px, -8px);
  }

  &:active {
    box-shadow:
      4px 4px 0px var(--color-gray-1),
      0px 0px 0px rgba(0, 0, 0, 0.05);
    transform: translate(-4px, -4px);
  }
`;

const CreatorImage = styled(Image)<{ version: number }>`
  position: absolute;
  top: -3rem;
  aspect-ratio: 1/1;
  border: 2px solid var(--color-gray-1);
  ${({ version }) => version === 1 && "border-radius: 0.5rem 0 0.5rem 0.5rem;"}
  ${({ version }) => version === 2 && "border-radius: 0.5rem 0.5rem 0.5rem 0px;"}
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

const CreatorTitle = styled.h3`
  color: var(--color-gray-1);
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
`;

const CreatorDescription = styled.p`
  color: var(--color-gray-1);
  font-weight: 400;
  font-size: 1rem;
  text-align: center;

  }
`;

const Creator = ({
  version,
  url,
  src,
  title,
  description,
}: {
  version: number;
  url: string;
  src: string;
  title: string;
  description: string;
}) => {
  return (
    <CreatorContainer title={`${title} is on Bunches`} href={url} version={version}>
      <CreatorImage version={version} src={src} width="80" height="80" alt="" />
      <CreatorTitle>{title}</CreatorTitle>
      <CreatorDescription>{description}</CreatorDescription>
    </CreatorContainer>
  );
};

const UpperText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-bottom: 2.5rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: 60%;
    margin-bottom: 0;
  }
`;

export default function BunchSection({ onCtaClick }: { onCtaClick: () => void }) {
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <Container id="creators">
      <UpperText>
        <HomeHeading>Who’s In</HomeHeading>
      </UpperText>
      <CreatorList>
        <Creator
          version={1}
          url="/bestcelticsgroupchat"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/cdb85b7ff97843f2b0e23334035a8d28.png"
          title="Best Celtics Group Chat☘️"
          description="•24/7 Celtics talk🗣️ •Celtics/NBA debates 🫣 •Tatum Incoming MVP?☘️"
        />
        <Creator
          version={2}
          url="/canecity"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/9a0b101177b54b2fb007b78ece2eec30.png"
          title="Cane City 🌴"
          description="@UMiamiFB on Instagram; Daily Chat and GameDay Talk 🔥"
        />
        <Creator
          version={1}
          url="/coreyscorner"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/c0198156f68e4163b32c30dd66b105b2.png"
          title="COREY’S CORNER 🔥🔥🔥"
          description="Group chat for the most passionate fan section in baseball. Corey’s Corner."
        />
        <Creator
          version={2}
          url="/$billsmafia"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/2e54f6fdc9204ca499fb1061aa211bc7.png"
          title="BillsMafia 💙❤️"
          description="A community of Bills fans to chat and talk all things Buffalo Bills and everything more 🦬"
        />
        <Creator
          version={1}
          url="/nyrdiscussionsgang"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/770f156c84874ada843d826ccce59d87.png"
          title="Nyr_Discussion’s Gang"
          description="Welcome to my chat! Let’s make this the best NYR platform on bunches!"
        />
        <Creator
          version={2}
          url="/everythingknicks"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/57742f27cbcf4b5fae3ec7ba6ee68e43.png"
          title="Everything Knicks 🗽"
          description="A forum dedicated to talking everything Knicks-related — games, trades, signings, rumors, reports, and more!"
        />
        <Creator
          version={1}
          url="/$pwhlreport"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/bc069b9c276c445ab23c038d9ec00b6e.png"
          title="PWHL Report"
          description="The best place for Women’s Hockey fans to chat PWHL!!"
        />
        <Creator
          version={2}
          url="/$texasgroupchat"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/271e3ad856764d1aa8042b660630a643.png"
          title="Texas Longhorns Chat🤘🏻🤘🏻"
          description="The groupchat for Texas sports and recruiting 🤘🏻"
        />
        <Creator
          version={1}
          url="/$chiefszone15groupchat"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/41e96a8ec4f840d3aef9c3a5583bfd00.png"
          title="Chiefszone15 Group Chat"
          description="Chiefs Group chat. Talk about Chiefs news, hot takes and more"
        />
        <Creator
          version={2}
          url="/$lalakerssquad"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/48447e711b134729a062ced27e1d1113.png"
          title="LALakersSquad Chat"
          description="Community for everything Lakers where we will discuss Off-season, Preseason, Regular Season, and Post-Season News 💜💛"
        />
        <Creator
          version={1}
          url="/$londonwillalwaysbeblue"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/b2b83b8db5044a39891d016071c352f3.png"
          title="Londonwillalwaysbeblue"
          description="For supporters of the biggest club in London 🔵"
        />
        <Creator
          version={2}
          url="/$eaglesfans"
          src="https://ik.imagekit.io/wrxztkausbq0/discover/tr:h-660/d4695f771b544e6b8bd4c32da09f82c0.png"
          title="Eagles Fans 🦅🔥"
          description="Everything Philadelphia Eagles🦅"
        />
      </CreatorList>
      <HeadingContainer>
        <HomeButton
          onClick={() => {
            pushTrackEvent("[Marketing] Tapped Let's Go (Bottom)");
            onCtaClick();
          }}
        >
          Let’s Go
        </HomeButton>
      </HeadingContainer>
    </Container>
  );
}
