import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";
import ShowcaseItem from "./ShowcaseItem";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  max-width: 1280px;
  position: relative;
  z-index: 2;
  padding: 0 2rem;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: var(--color-background);

  @media (min-width: ${MEDIA_QUERY_MD}) {
    gap: 0;
  }
`;

export default function FeatureShowcase({ onCtaClick }: { onCtaClick: () => void }) {
  return (
    <Container>
      <ShowcaseItem
        imgUrl="/img/lp/chat-sports.png"
        heading="Chat Sports"
        subheading="all day on bunches"
        description="Rep your teams and chat with like-minded fans. Talk about sports in real time with others who care, almost as much as you."
        onCtaClick={onCtaClick}
      />
      <ShowcaseItem
        imgUrl="/img/lp/scoreboard.png"
        heading="Share Moments"
        subheading="live the game in real time"
        description="See what’s on today and get scores to the games you follow. Join a game Bunch, and rave about moments as they happen."
        onCtaClick={onCtaClick}
        flip
      />
      <ShowcaseItem
        imgUrl="/img/lp/discover-sports.png"
        heading="Fan Together"
        subheading="bring your passion"
        description="Find your Bunch, analyse plays and rant about the latest trades. Unlock your fandom and experience more of your favorite sports."
        onCtaClick={onCtaClick}
      />
    </Container>
  );
}
