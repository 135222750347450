import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../utilities/constants/mediaQueries";

const HomeHeading = styled.h1`
  font-family: var(--font-family-headline);
  font-size: 27vw;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 12.25rem;
  }
`;

export default HomeHeading;
