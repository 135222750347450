import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import { MEDIA_QUERY_MD, MEDIA_QUERY_SM } from "../../utilities/constants/mediaQueries";
import { useAnalyticsContext } from "../state/AnalyticsProvider";

const Header = styled(motion.header)`
  background: var(--color-gray-7);
  position: sticky;
  top: 0;
  z-index: var(--z-header);
  border-bottom: 1px solid var(--color-gray-1);
  border-top: 1px solid var(--color-gray-1);
`;

const Inner = styled.nav`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const LogoContainer = styled.div`
  display: flex;
  color: var(--color-gray-1);
  align-items: center;
  gap: 0;
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (min-width: ${MEDIA_QUERY_SM}) {
    flex-direction: row;
    gap: 1.5rem;
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    gap: 3rem;
  }
`;

const HeaderButton = styled.button`
  align-items: baseline;
  display: flex;
  font-family: var(--font-family-headline);
  font-size: 0.875rem;
  gap: 1rem;
  padding: 0;
  letter-spacing: 1px;
  color: var(--color-gray-1);
  transition: color var(--animation-timing-quick) var(--timing-easeOutQuart);

  @media (min-width: ${MEDIA_QUERY_SM}) {
    padding: 0.5rem 0;
  }

  & > span:after {
    content: "";
    display: block;
    border-bottom: 2px solid transparent;
    width: 0rem;
    transition:
      border-color var(--animation-timing-quick) var(--timing-easeOutQuart),
      width var(--animation-timing-quick) var(--timing-easeOutQuart);
  }

  &:hover {
    color: var(--color-gray-1);
  }

  &:hover > span:after {
    border-color: var(--color-primary);
    width: 1rem;
  }

  &:active,
  &:focus {
    color: var(--color-primary);
  }

  &:active > span:after,
  &:focus > span:after {
    border-color: var(--color-primary);
    width: 1rem;
  }
`;

export const StickyHeader = ({
  className,
  onCtaClick = undefined,
}: {
  className?: string;
  onCtaClick?: () => void;
}) => {
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <Header initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className={className}>
      <Inner>
        <Link href="/">
          <LogoContainer>
            <Image src="/img/app_logo.png" width={40} height={40} alt="Bunches Logo" priority /> Bunches
          </LogoContainer>
        </Link>
        <ButtonContainer>
          <Link href="/scouts" onClick={() => pushTrackEvent("[Marketing] Tapped Scouts")}>
            <HeaderButton>
              <span>Scouts</span>
            </HeaderButton>
          </Link>
          <HeaderButton
            onClick={() => {
              pushTrackEvent("[Marketing] Tapped Download Now (Header)");
              onCtaClick?.();
            }}
          >
            <span>Download Now</span>
          </HeaderButton>
        </ButtonContainer>
      </Inner>
    </Header>
  );
};
