import styled from "styled-components";
import Image from "next/image";
import {
  MEDIA_QUERY_MD,
  MEDIA_QUERY_SM,
  MEDIA_QUERY_XL,
  MEDIA_QUERY_XXXL,
} from "../../../utilities/constants/mediaQueries";
import HomeButton from "../HomeButton";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import FooterBanner from "../../scout/FooterBanner";
import { isMobile } from "react-device-detect";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-gray-7);
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;

  @media (min-height: 630px) {
    height: calc(100dvh - 62px);
  }

  @media (min-width: ${MEDIA_QUERY_SM}) and (min-height: 630px) {
    height: calc(100dvh - 58px);
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2rem;
  z-index: 2;
  height: calc(100dvh - 4rem);

  @media (min-width: ${MEDIA_QUERY_MD}) {
    height: auto;
  }

  @media (min-width: ${MEDIA_QUERY_XL}) {
    font-size: 12.25rem;
    padding: 0 1rem;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  z-index: 2;
  gap: 1rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    gap: 0;
  }
`;

const Heading = styled.h1`
  font-size: 23vw;
  text-transform: uppercase;
  letter-spacing: -10px;
  line-height: 0.7;
  margin: auto 0 0;
  font-weight: 900;
  padding: 1rem 0;
  margin-bottom: 1rem;

  -webkit-text-fill-color: transparent;
  background-color: var(--color-gray-1);
  background-image: url("/img/gifs/JamalMurray2021.gif");
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 150% 150%;
  background-position: 100% center;

  @media (min-width: ${MEDIA_QUERY_XL}) {
    font-size: min(32vh, 21vw);
  }
`;

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HeroQR = styled(({ shouldHide, ...props }) => <Image width={80} height={80} alt="Download" {...props} />)<{
  shouldHide: boolean;
}>`
  width: 100px;

  ${({ shouldHide }) =>
    shouldHide &&
    `
    visibility: hidden;
  `}

  @media (min-width: ${MEDIA_QUERY_XXXL}) {
    width: 120px;
  }
`;

const DownArrow = styled(Image)`
  margin: 1rem 0;
  animation: arrowAnimation 0.8s var(--timing-easeInOutQuart) infinite alternate;

  @keyframes arrowAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(10px);
    }
  }
`;

export default function HeroSection({ onCtaClick }: { onCtaClick: () => void }) {
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <Container>
      {/* <Gradient /> */}
      {/* <Gradient2 /> */}
      <Inner>
        {/* <Header>
          <Image src="/img/app-logo-home.svg" width={48} height={48} alt="Bunches" priority />
        </Header> */}
        <HeroContent>
          <Heading>
            Chat
            <br />
            Sports
          </Heading>
          <CtaContainer>
            <HomeButton
              onClick={() => {
                pushTrackEvent("[Marketing] Tapped Let's Go");
                onCtaClick();
              }}
            >
              LET’S GO
            </HomeButton>
            <HeroQR src="/img/apple-qr-code-large.svg" shouldHide={isMobile} />
          </CtaContainer>
          <DownArrow src="/img/arrow-down-black.svg" width={14} height={35} alt="" />
        </HeroContent>
      </Inner>
      <FooterBanner />
    </Container>
  );
}
