import BunchSection from "./sections/BunchSection";
import FeatureShowcase from "./sections/FeatureShowcase";
import HeroSection from "./sections/HeroSection";
import HomeFlyout from "./HomeFlyout";
import HomeFooter from "./sections/Footer";
import HomeSignup from "./HomeSignup";
import styled from "styled-components";
import { DOWNLOAD_URL_ANDROID, DOWNLOAD_URL_IOS } from "../../utilities/constants/urls";
import { ReactNode, useEffect, useRef, useState } from "react";
import { StickyHeader } from "./StickyHeader";
import { isIOS, isMobile } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import { useRouter } from "next/router";

const Container = styled.div`
  position: relative;
  contain: paint;
`;

const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
  position: relative;
`;

const SignupSection = styled.div`
  background: var(--color-gray-1) url("/img/flyout-bg.png") center center repeat;
  background-size: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
`;

export default function HomeLayout({ children }: { children: ReactNode }) {
  const router = useRouter();
  const defaultFlyoutOpen = !!(router.query.qr === "1");
  const [flyoutOpen, setFlyoutOpen] = useState<boolean>(defaultFlyoutOpen);
  const ctaLink = isIOS ? DOWNLOAD_URL_IOS : DOWNLOAD_URL_ANDROID;
  const ref = useRef(null);

  useEffect(() => {
    if (defaultFlyoutOpen) {
      setFlyoutOpen(true);
    }
  }, [defaultFlyoutOpen]);

  useHotkeys("Esc", () => setFlyoutOpen(false));

  const onCtaClick = () => (isMobile ? router.push(ctaLink) : setFlyoutOpen(true));
  return (
    <>
      <HomeFlyout isOpen={flyoutOpen} onClose={() => setFlyoutOpen(false)} />
      <Container>
        {children}
        <StickyHeader onCtaClick={onCtaClick} />
        <HeroSection onCtaClick={onCtaClick} />
        <Main ref={ref}>
          <FeatureShowcase onCtaClick={onCtaClick} />
          {/* <StatsSection onCtaClick={() => setFlyoutOpen(true)} /> */}
          <BunchSection onCtaClick={onCtaClick} />
          {!isMobile && (
            <SignupSection>
              <HomeSignup />
            </SignupSection>
          )}
          <HomeFooter />
        </Main>
      </Container>
    </>
  );
}
