import Image from "next/image";
import styled from "styled-components";
import { MEDIA_QUERY_MD, MEDIA_QUERY_SM, MEDIA_QUERY_XL } from "../../../utilities/constants/mediaQueries";
import { ScoutHeading } from "../../scout/ScoutText";
import Text from "../../shared/Text";
import HomeButton from "../HomeButton";

type ShowcaseItemProps = {
  imgUrl: string;
  heading: string;
  subheading: string;
  description: string;
  onCtaClick: () => void;
  flip?: boolean;
};

const Showcase = styled.div<{ flip: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: ${({ flip }) => (flip ? "row-reverse" : "row")};
    gap: 2.5rem;
  }

  @media (min-width: ${MEDIA_QUERY_MD}) and (min-height: 500px) {
    height: calc(100dvh - 58px);
  }
`;

const ShowcaseRightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  width: 100%;
`;

const HeroGifContainer = styled.div`
  position: relative;
  max-width: 200px;
  padding: 1rem 0;
  z-index: 1;
  display: flex;

  @media (min-width: ${MEDIA_QUERY_SM}) and (min-height: 650px) {
    max-width: 300px;
    padding: 0;
  }
`;

const GifOver = styled(Image)`
  z-index: 2;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Heading = styled(ScoutHeading)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 4rem;
  -webkit-text-stroke: 1px var(--color-gray-1);
  font-weight: 600;
  flex-wrap: wrap;

  & > span {
    color: var(--color-background);
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 5rem;
  }

  @media (min-width: ${MEDIA_QUERY_XL}) {
    font-size: 8rem;
  }
`;

const Subheading = styled.h5`
  font-family: var(--font-family-headline);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const StyledText = styled(Text)`
  font-size: 1.25rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 1.5rem;
  }
`;

export default function ShowcaseItem({
  imgUrl,
  heading,
  subheading,
  description,
  onCtaClick,
  flip = false,
}: ShowcaseItemProps) {
  const headingWords = heading.split(" ");
  return (
    <Showcase flip={flip}>
      <HeroGifContainer>
        <GifOver src={imgUrl} alt="" width="411" height="877" />
      </HeroGifContainer>
      <ShowcaseRightSide>
        <TitleContainer>
          <Heading>
            {headingWords[0]} <span>{headingWords[1]}</span>
          </Heading>
          <Subheading>{subheading}</Subheading>
        </TitleContainer>
        <StyledText>{description}</StyledText>
        <HomeButton onClick={onCtaClick}>{"Let's go"}</HomeButton>
      </ShowcaseRightSide>
    </Showcase>
  );
}
