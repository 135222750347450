import Cookies from "js-cookie";
import Head from "../components/shared/header/Head";
import HomeLayout from "../components/home/HomeLayout";
import { useAnalyticsContext } from "../components/state/AnalyticsProvider";
import { useEffect } from "react";

export default function Home() {
  const { pushTrackEvent } = useAnalyticsContext();
  const hasLoggedIn: boolean = !!Cookies.get("hasLoggedIn");

  useEffect(() => {
    pushTrackEvent("[Marketing] Visited Home Page");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeLayout hasLoggedIn={hasLoggedIn}>
      <Head title="Bunches" insightTracking />
    </HomeLayout>
  );
}
